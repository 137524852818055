import React, { useEffect, useRef, useState } from 'react'
import { DeliverableOptions, ReviewScorecardInterface } from '@src/interfaces/performance'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Card } from '@src/pages/Forms/EmployeePerformanceLayout/Card'
import {
  CardContentTypes,
  CommonCardProps,
  getKpiGrades,
  PerfRatingToGraphNumber,
} from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import { get, set } from 'lodash'
import { HelpTabs } from '@src/pages/Forms/EmployeePerformance/components/HelpSections/CombinedHelp'
import { KPI } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/KPI'
import { KPIsCardTable } from '@src/pages/Forms/EmployeePerformanceLayout/components/KPIsCardTable'
import { getGradeFromPerformance } from '@src/constants/columns/kpi'
import isNumber from 'lodash/isNumber'
import { useRecommendedGradesContext } from '@src/pages/Forms/EmployeePerformanceLayout/ScorecardContent'

interface KPIscardInterface extends CommonCardProps {
  setMissingKpiJustification?: React.Dispatch<React.SetStateAction<boolean>>
  showBeforeSubmitCheckError?: boolean
}

export const KPIsCard = connect(
  ({
    onHelpClick,
    gradesMap,
    setMissingKpiJustification,
    showBeforeSubmitCheckError,
  }: KPIscardInterface) => {
    const { values, errors } = useLapeContext<ReviewScorecardInterface>()
    const hasKPI = !!values.review_data.kpis_section
    const ref = useRef<HTMLDivElement>(null)
    const { grades } = useRecommendedGradesContext()

    const [gradeRecommendation, setGradeRecommendation] =
      useState<DeliverableOptions | null>(null)

    useEffect(() => {
      if (errors.review_data?.kpis_section || showBeforeSubmitCheckError) {
        ref?.current?.scrollIntoView({ behavior: 'smooth' })
      }
    }, [errors.review_data?.kpis_section, showBeforeSubmitCheckError])

    useEffect(() => {
      if (values.review_data.kpis_section) {
        const missingValue = !values.review_data.kpis_section.recommended_rating
        const performance = values.review_data.kpis_section.performance
        if (isNumber(performance)) {
          const deliverableOption = getGradeFromPerformance(
            performance,
            gradesMap,
          ).deliverable

          setGradeRecommendation(deliverableOption)
          if (missingValue) {
            values.review_data.kpis_section.recommended_rating = deliverableOption
          }
        }
      }
    }, [values.review_data.kpis_section])

    if (!hasKPI) {
      return null
    }

    const kpiGrades = getKpiGrades(gradesMap, true)
    const finalRating = grades?.kpiGrade ? gradesMap[grades?.kpiGrade] : undefined

    const gradeValue = values.review_data.kpis_section?.recommended_rating
    const missingJustification = !values.review_data.kpis_section?.comment
    if (gradeRecommendation && gradeValue && missingJustification) {
      const recommendationScore = PerfRatingToGraphNumber[gradeRecommendation]
      const valueScore = PerfRatingToGraphNumber[gradeValue]
      setMissingKpiJustification?.(valueScore > recommendationScore)
    } else {
      setMissingKpiJustification?.(false)
    }

    return (
      <Card
        data={values}
        renderExpandedContent={() => (
          <KPI
            reviewData={values.review_data}
            gradeRecommendation={gradeRecommendation || undefined}
            kpiGrades={kpiGrades}
          />
        )}
        renderExceedingContent={() => (
          <KPI
            reviewData={values.review_data}
            kpiGrades={kpiGrades}
            gradeRecommendation={gradeRecommendation || undefined}
            showJustificationError={showBeforeSubmitCheckError && missingJustification}
            justificationOnly
          />
        )}
        additionalInfo={<KPIsCardTable gradesMap={gradesMap} />}
        type={CardContentTypes.KPI}
        title="Goals"
        stat={values.review_data.kpis_section?.performance}
        finalRating={finalRating}
        icon="Target"
        fields={[
          {
            field: 'review_data.kpis_section',
            title: 'Goal performance',
            grades: kpiGrades,
            gradeRecommendation,
          },
        ]}
        onSelectGrade={grade => {
          set(values, `review_data.kpis_section.recommended_rating`, grade.key)
        }}
        isGradeSelectedRule={(field, grade) => {
          const ratingValue = get(values, field)?.recommended_rating
          return !!ratingValue && ratingValue === grade.key
        }}
        headerRef={ref}
        onHelpClick={() => onHelpClick?.(HelpTabs.Deliverables)}
      />
    )
  },
)
